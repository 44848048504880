/* Ensure the Ant Design CSS is imported before your custom styles */

.welcome-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #000;
  color: #fff;
  font-family: 'Arial', sans-serif;
  text-align: center;
}

.welcome-container .ant-typography {
  color: #fff; /* Ensure the text color is white */
}

.ring-container {
  width: 200px; /* Adjust the size as needed */
  height: 200px; /* Adjust the size as needed */
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  cursor: pointer;
  position: relative;
  border-radius: 50%;
  outline: none;
  user-select: none;
}

.glow-ring {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: radial-gradient(circle at center, rgba(255,0,0,0) 60%, rgb(222, 20, 81) 70%, darkred 80%, black 90%);
  position: absolute;
  z-index: 1;
  outline: none;
  user-select: none;
}

.active-session {
  animation: glowing 2.0s infinite;

}
.start-button {
  font-size: 1rem;
  font-weight: bold;
  color: white;
  background-color: black; /* Adjust color to match the design */
  border: none;
  z-index: 2;
  position: relative;
  transition: transform 0.2s, box-shadow 0.2s;
  outline: none;
  user-select: none;
}

@keyframes glowing {
  0%, 100% {
    box-shadow: 0 0 5px #ff5555, 0 0 10px #88ff55, 0 0 15px #17f243, 0 0 20px #e0ff55, 0 0 25px #0153ea, 0 0 30px #ff5555, 0 0 35px #ff5555;
  }
  50% {
    box-shadow: 0 0 10px #ff5555, 0 0 20px #ff5555, 0 0 30px #ff5555, 0 0 40px #f114df, 0 0 50px #55ffff, 0 0 60px #ff5555, 0 0 70px #a2f869;
  }
}
.start-button:active {
  transform: scale(0.95); /* Slightly scale down the button */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Adjusted shadow for a "pressed" effect */
}
.ring-container:focus {
  outline: none;
}
.start-button,
.start-button:focus,
.start-button:active {
  outline: none;
  box-shadow: none; /* Removes additional focus or active shadows */
  -moz-user-select: none; /* Firefox */
  -webkit-user-select: none; /* Chrome/Safari */
  -ms-user-select: none; /* IE/Edge */
  user-select: none; /* Standard */
}

/* Remove text selection styling */
.start-button::selection {
  background: none;
}

/* Remove Webkit tap highlight color for mobile browsers */
.start-button::-webkit-tap-highlight-color {
  background-color: transparent;
}
.start-button,
.ring-container {
  /* Remove tap highlight color on touch devices */
  -webkit-tap-highlight-color: transparent;
  /* Other styles... */
}

.start-button:focus,
.start-button:active,
.ring-container:focus {
  outline: none;
  box-shadow: none; /* Removes additional focus or active shadows */
}

/* Ensuring no text or content is selectable */
.start-button,
.ring-container {
  -moz-user-select: none; /* Firefox */
  -webkit-user-select: none; /* Chrome/Safari */
  -ms-user-select: none; /* IE/Edge */
  user-select: none; /* Standard */
}

/* Remove text selection styling */
::selection {
  background: none;
}